"use client";

import {
    WalletWithMetadata,
    usePrivy,
    User,
    useWallets,
} from "@privy-io/react-auth";
import { useMemo } from "react";


const getEmbeddedWallet = (user: User | null) =>
    user?.linkedAccounts.find(
        (account): account is WalletWithMetadata =>
            account.type === "wallet" &&
            account.walletClientType === "privy" &&
            account.connectorType === "embedded"
    );

/**
 * Checks for linked ownership wallet to Privy account
 * @param user: Privy user object
 * @returns WalletWithMetadata | null: ownership wallet address or null
 */
const getOwnershipWallet = (user: User | null): WalletWithMetadata | null => {
    if (!user?.linkedAccounts) {
        return null;
    }

    const eoaWallet = user.linkedAccounts.find(
        (account): account is WalletWithMetadata =>
            account.type === "wallet" && account.walletClientType !== "privy"
    );

    return eoaWallet ?? getEmbeddedWallet(user) ?? null;
};

// This will not work for all chains, but it does for ours...
// Reference: https://github.com/ChainAgnostic/CAIPs/blob/main/CAIPs/caip-2.md
// export const resolveChainId = (wallet: ConnectedWallet) =>
//   parseInt(wallet.chainId.split(":")[1]);
export const useAuthWallets = () => {
    const { ready: isReady, user: privyUser } = usePrivy();
    const { wallets, ready: walletsReady } = useWallets();

    const user = useMemo(() => {
        if (!privyUser) {
            return null;
        }

        const email =
            (privyUser?.email?.address ||
                privyUser?.google?.email ||
                privyUser?.discord?.email) ??
            null;

        return {
            email,
        };
    }, [privyUser]);

    const ownershipWallet = useMemo(

        () => {
            return (walletsReady ? getOwnershipWallet(privyUser) : null)
        },
        [privyUser, walletsReady]
    );

    const connectedOwnershipWallet = useMemo(
        () => {
            if (privyUser?.wallet?.connectorType === "embedded") {
                return ownershipWallet;
            } else if (privyUser?.wallet?.connectorType === "injected") {
                return ownershipWallet && (wallets.find((wallet) => wallet.address === ownershipWallet?.address) ??
                    null)
            }
        }, [wallets, ownershipWallet, privyUser]
    );

    const isOwnershipWalletLoading = Boolean(walletsReady && !ownershipWallet);

    const isOwnershipWalletDisconnected = privyUser && walletsReady ? walletsReady && Boolean(ownershipWallet?.address !== connectedOwnershipWallet?.address) : null
    // TODO: Set this within ownershipWallet
    const ownershipPrivyWallet = wallets.find((connectedWallet) => connectedWallet.address === ownershipWallet?.address);


    return {
        connectedOwnershipWallet,
        isComplete: Boolean(isReady && user?.email),
        isOwnershipWalletLoading,
        isOwnershipWalletDisconnected,
        isReady,
        ownershipWallet,
        ownershipPrivyWallet,
        user,
        walletsReady,
    };
};
