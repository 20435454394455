import * as amplitude from '@amplitude/analytics-browser'
import AnalyticsProvider, {
  initializeAnalytics,
} from 'components/AnalyticsProvider'
initializeAnalytics()
import ErrorTrackingProvider from 'components/ErrorTrackingProvider'

import { Inter } from 'next/font/google'
import type { AppContext, AppProps } from 'next/app'
import { default as NextApp } from 'next/app'
import { ThemeProvider, useTheme } from 'next-themes'
import { darkTheme, globalReset } from 'stitches.config'
import * as Tooltip from '@radix-ui/react-tooltip'
import {
  ReservoirKitProvider,
  ReservoirKitTheme,
  CartProvider,
} from '@reservoir0x/reservoir-kit-ui'
import { FC, useContext, useEffect, useState } from 'react'
import { HotkeysProvider } from 'react-hotkeys-hook'
import NextTopLoader from 'nextjs-toploader'
import ToastContextProvider from 'context/ToastContextProvider'
import supportedChains from 'utils/chains'
import { useMarketplaceChain } from 'hooks'
import ChainContextProvider from 'context/ChainContextProvider'
import CollectionContextProvider from 'context/CollectionContextProvider'
import { WebsocketContextProvider } from 'context/WebsocketContextProvider'
import ReferralContextProvider, {
  ReferralContext,
} from 'context/ReferralContextProvider'
import { chainPaymentTokensMap } from 'utils/paymentTokens'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { http } from 'wagmi'
import { Transport } from 'viem'
import {
  WagmiProvider as PrivyWagmiProvider,
  createConfig,
} from '@privy-io/wagmi'
import { Chain, mainnet, skaleNebula } from 'viem/chains'
import { PrivyProvider } from 'config/privy'
import PriceDisplayContextProvider from 'context/PriceDisplayContextProvider'
import { ReservoirChain } from '@reservoir0x/reservoir-sdk'

// CONFIGURABLE: Use nextjs to load your own custom font: https://nextjs.org/docs/basic-features/font-optimization
const inter = Inter({
  subsets: ['latin'],
})

type Transports = Record<ReservoirChain['id'], Transport>

export const NORMALIZE_ROYALTIES = process.env.NEXT_PUBLIC_NORMALIZE_ROYALTIES
  ? process.env.NEXT_PUBLIC_NORMALIZE_ROYALTIES === 'true'
  : false

const DISABLE_PROXY =
  process.env.NEXT_PUBLIC_DISABLE_PROXY === 'true' ? true : false

const privyWagmiConfig = createConfig({
  chains: (supportedChains.length === 0 ? [mainnet] : supportedChains) as [
    Chain,
    ...Chain[]
  ],
  transports: supportedChains.reduce((transportsConfig: Transports, chain) => {
    transportsConfig[chain.id] = http()

    return transportsConfig
  }, {}),
  ssr: true,
})

const queryClient = new QueryClient()

function AppWrapper(props: AppProps & { baseUrl: string }) {
  useEffect(() => {
    // Initialize analytics trackers
    if (process.env.NEXT_PUBLIC_AMPLITUDE_KEY) {
      amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, undefined, {
        logLevel: amplitude.Types.LogLevel.Warn,
        serverUrl: `${window.location.protocol}//${window.location.host}/analytics/2/httpapi`,
      })
    }
  }, [])

  return (
    <ThemeProvider
      attribute="class"
      defaultTheme="dark"
      value={{
        dark: darkTheme.className,
      }}
    >
      <PrivyProvider>
        <QueryClientProvider client={queryClient}>
          <ChainContextProvider>
            <PrivyWagmiProvider config={privyWagmiConfig}>
              <AnalyticsProvider>
                <ErrorTrackingProvider>
                  <ReferralContextProvider>
                    <CollectionContextProvider>
                      <NextTopLoader
                        showSpinner={false}
                        height={4}
                        color="#5C5FD3"
                      />
                      <PriceDisplayContextProvider>
                        <MyApp {...props} />
                      </PriceDisplayContextProvider>
                    </CollectionContextProvider>
                  </ReferralContextProvider>
                </ErrorTrackingProvider>
              </AnalyticsProvider>
            </PrivyWagmiProvider>
          </ChainContextProvider>
        </QueryClientProvider>
      </PrivyProvider>
    </ThemeProvider>
  )
}

function MyApp({
  Component,
  pageProps,
  baseUrl,
}: AppProps & { baseUrl: string }) {
  globalReset()

  const marketplaceChain = useMarketplaceChain()
  const [reservoirKitTheme, setReservoirKitTheme] = useState<
    ReservoirKitTheme | undefined
  >()

  const { feesOnTop } = useContext(ReferralContext)

  const FunctionalComponent = Component as FC

  let source = process.env.NEXT_PUBLIC_MARKETPLACE_SOURCE

  if (!source && process.env.NEXT_PUBLIC_HOST_URL) {
    try {
      const url = new URL(process.env.NEXT_PUBLIC_HOST_URL)
      source = url.host
    } catch (e) {}
  }

  return (
    <HotkeysProvider>
      <ThemeProvider
        attribute="class"
        defaultTheme="dark"
        value={{
          dark: darkTheme.className,
          light: 'light',
        }}
      >
        <ReservoirKitProvider
          options={{
            // Reservoir API key which you can generate at https://reservoir.tools/
            // This is a protected key and displays as 'undefined' on the browser
            // DO NOT add NEXT_PUBLIC to the key or you'll risk leaking it on the browser
            apiKey: process.env.RESERVOIR_API_KEY,
            //CONFIGURABLE: Override any configuration available in RK: https://docs.reservoir.tools/docs/reservoirkit-ui#configuring-reservoirkit-ui
            // Note that you should at the very least configure the source with your own domain
            chains: supportedChains.map(
              ({
                reservoirBaseUrl,
                proxyApi,
                id,
                name,
                checkPollingInterval,
              }) => {
                let proxy = !DISABLE_PROXY && proxyApi ? proxyApi : null
                return {
                  id,
                  name,
                  baseApiUrl: proxy
                    ? `${baseUrl}${proxyApi}`
                    : reservoirBaseUrl,
                  proxyApi: proxy,
                  active: marketplaceChain.id === id,
                  checkPollingInterval: checkPollingInterval,
                  paymentTokens: chainPaymentTokensMap[id],
                }
              }
            ),
            logLevel: 4,
            source: source,
            normalizeRoyalties: NORMALIZE_ROYALTIES,
            //CONFIGURABLE: Set your marketplace fee and recipient, (fee is in BPS)
            // Note that this impacts orders created on your marketplace (offers/listings)
            // marketplaceFees: ['0x03508bB71268BBA25ECaCC8F620e01866650532c:250'],
            convertLink:
              marketplaceChain.id === skaleNebula.id
                ? {
                    customUrl: ({ toCurrency }) => {
                      if (!toCurrency || toCurrency.symbol === 'ETH') {
                        return 'https://portal.skale.space/bridge?from=mainnet&to=green-giddy-denebola&token=eth&type=eth'
                      } else if (toCurrency) {
                        return `https://portal.skale.space/bridge?from=mainnet&to=green-giddy-denebola&token=${toCurrency.symbol}&type=erc20`
                      } else {
                        return 'https://portal.skale.space/bridge?from=mainnet&to=green-giddy-denebola'
                      }
                    },
                  }
                : undefined,
          }}
          theme={reservoirKitTheme}
        >
          <CartProvider feesOnTopUsd={feesOnTop}>
            <WebsocketContextProvider>
              <Tooltip.Provider>
                <ToastContextProvider>
                  <FunctionalComponent {...pageProps} />
                </ToastContextProvider>
              </Tooltip.Provider>
            </WebsocketContextProvider>
          </CartProvider>
        </ReservoirKitProvider>
      </ThemeProvider>
    </HotkeysProvider>
  )
}

AppWrapper.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await NextApp.getInitialProps(appContext)
  let baseUrl = ''

  if (process.env.NEXT_PUBLIC_PROXY_URL) {
    baseUrl = process.env.NEXT_PUBLIC_PROXY_URL
  } else if (process.env.NEXT_PUBLIC_HOST_URL) {
    baseUrl = process.env.NEXT_PUBLIC_HOST_URL || ''
  }
  baseUrl = baseUrl.replace(/\/$/, '')

  return { ...appProps, baseUrl }
}

export default AppWrapper
