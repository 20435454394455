import { Currency } from '@reservoir0x/reservoir-kit-ui'
import { reservoirChains, customChains } from '@reservoir0x/reservoir-sdk'
import { zeroAddress } from 'viem'
import {
  mainnet,
  Chain,
  sepolia,
  base,
  arbitrum,
  optimism,
  zora,
  arbitrumNova,
} from 'wagmi/chains'
import usdcContracts from './usdcContracts'

//CONFIGURABLE: The default export controls the supported chains for the marketplace. Removing
// or adding chains will result in adding more or less chains to the marketplace.
// They are an extension of the wagmi chain objects

export type ReservoirChain = Chain & {
  lightIconUrl: string
  darkIconUrl: string
  reservoirBaseUrl: string
  proxyApi?: string
  routePrefix: string
  apiKey?: string
  coingeckoId?: string
  collectionSetId?: string
  community?: string
  wssUrl?: string
  listingCurrencies?: Currency[]
  oracleBidsEnabled?: boolean
  checkPollingInterval?: number
  paperContractId?: string
}

const nativeCurrencyBase = {
  contract: zeroAddress,
  symbol: 'ETH',
  decimals: 18,
  coinGeckoId: 'ethereum',
}

const usdcCurrencyBase = {
  contract: '',
  symbol: 'USDC',
  decimals: 6,
  coinGeckoId: 'usd-coin',
}

const proxyDisabled = process.env.NEXT_PUBLIC_DISABLE_PROXY === 'true'

const liveDefaultChain: ReservoirChain = {
  ...mainnet,
  // Any url to display the logo of the chain in light mode
  lightIconUrl: '/icons/eth-icon-dark.svg',
  // Any url to display the logo of the chain in dark mode
  darkIconUrl: '/icons/eth-icon-light.svg',
  // The base url of the reservoir api, this is used in the app when
  // directly interacting with the reservoir indexer servers (in the api proxy for example)
  // or when prefetching server side rendered data
  reservoirBaseUrl: reservoirChains.mainnet.baseApiUrl,
  // Used on the client side portions of the marketplace that need an api key added
  // Prevents the api key from being leaked in the clientside requests
  // If you'd like to disable proxying you can just change the proxyApi to the reservoirBaseUrl
  // Doing so will omit the api key unless further changes are made
  proxyApi: `${proxyDisabled ? '/api/reservoir' : ''}/ethereum`,
  // A prefix used in the asset specific routes on the app (tokens/collections)
  routePrefix: 'ethereum',
  // Coingecko id, used to convert the chain's native prices to usd. Can be found here:
  // https://www.coingecko.com/en/api/documentation#operations-coins-get_coins_list
  coingeckoId: 'ethereum',
  collectionSetId: process.env.NEXT_PUBLIC_ETH_COLLECTION_SET_ID,
  community: process.env.NEXT_PUBLIC_ETH_COMMUNITY,
  wssUrl: 'wss://ws.reservoir.tools',
  listingCurrencies: [
    nativeCurrencyBase,
    {
      ...usdcCurrencyBase,
      contract: usdcContracts[mainnet.id],
    },
  ],
  oracleBidsEnabled: true,
  checkPollingInterval: reservoirChains.mainnet.checkPollingInterval,
  paperContractId: process.env.PAPER_ETHEREUM_CONTRACT_ID,
  rpcUrls: {
    default: {
      http: [
        'https://capable-lively-season.quiknode.pro/1aa2dc118759b1fefe8a3f0d16427c93145240cd/',
      ],
    },
  },
}

const testnetDefaultChain: ReservoirChain = {
  ...sepolia,
  // Any url to display the logo of the chain in light mode
  lightIconUrl: '/icons/goerli-icon-dark.svg',
  // Any url to display the logo of the chain in dark mode
  darkIconUrl: '/icons/goerli-icon-light.svg',
  // The base url of the reservoir api, this is used in the app when
  // directly interacting with the reservoir indexer servers (in the api proxy for example)
  // or when prefetching server side rendered data
  reservoirBaseUrl: reservoirChains.sepolia.baseApiUrl,
  // Used on the client side portions of the marketplace that need an api key added
  // Prevents the api key from being leaked in the clientside requests
  // If you'd like to disable proxying you can just change the proxyApi to the reservoirBaseUrl
  // Doing so will omit the api key unless further changes are made
  proxyApi: `${proxyDisabled ? '/api/reservoir' : ''}/sepolia`,
  // A prefix used in the asset specific routes on the app (tokens/collections)
  routePrefix: 'sepolia',
  // Coingecko id, used to convert the chain's native prices to usd. Can be found here:
  // https://www.coingecko.com/en/api/documentation#operations-coins-get_coins_list
  coingeckoId: 'ethereum',
  collectionSetId: process.env.NEXT_PUBLIC_SEPOLIA_COLLECTION_SET_ID,
  community: process.env.NEXT_PUBLIC_SEPOLIA_COMMUNITY,
  wssUrl: 'wss://ws-sepolia.reservoir.tools',
  checkPollingInterval: reservoirChains.sepolia.checkPollingInterval,
}

export const DefaultChain =
  process.env.NEXT_PUBLIC_PUBLIC_ENVIRONMENT === 'production'
    ? liveDefaultChain
    : testnetDefaultChain

const testnets = [
  DefaultChain,
  {
    // barret
    ...customChains.apexPopTestnet,
    lightIconUrl: '/icons/apex-pop-icon-light.svg',
    darkIconUrl: '/icons/apex-pop-icon-dark.svg',
    reservoirBaseUrl: reservoirChains.apexPopTestnet.baseApiUrl,
    proxyApi: `${proxyDisabled ? '/api/reservoir' : ''}/apex-pop-testnet`,
    routePrefix: 'apex-pop-testnet',
    coingeckoId: 'ethereum',
    checkPollingInterval: reservoirChains.apexPop.checkPollingInterval,
  },
  {
    ...customChains.cloud,
    name: 'Cloud',
    lightIconUrl: '/icons/cloud-icon-light.svg',
    darkIconUrl: '/icons/cloud-icon-dark.svg',
    reservoirBaseUrl: reservoirChains.cloud.baseApiUrl,
    proxyApi: `${proxyDisabled ? '/api/reservoir' : ''}/cloud`,
    routePrefix: 'cloud',
    apiKey: process.env.RESERVOIR_API_KEY,
    coingeckoId: 'ethereum',
    oracleBidsEnabled: true,
    checkPollingInterval: reservoirChains.cloud.checkPollingInterval,
  },
] as ReservoirChain[]

const live = [
  DefaultChain,
  {
    ...customChains.apexPop,
    name: 'Apex',
    lightIconUrl: '/icons/aex-pop-icon-light.svg',
    darkIconUrl: '/icons/apex-pop-icon-dark.svg',
    reservoirBaseUrl: reservoirChains.apexPop.baseApiUrl,
    proxyApi: `${proxyDisabled ? '/api/reservoir' : ''}/apex`,
    routePrefix: 'apex',
    apiKey: process.env.RESERVOIR_API_KEY,
    coingeckoId: 'ethereum',
    collectionSetId: process.env.NEXT_PUBLIC_APE_COLLECTION_SET_ID,
    community: process.env.NEXT_PUBLIC_APEX_COMMUNITY,
    oracleBidsEnabled: true,
    checkPollingInterval: reservoirChains.apexPop.checkPollingInterval,
  },
  {
    ...customChains.boss,
    name: 'Boss',
    lightIconUrl: '/icons/apex-pop-icon-light.svg',
    darkIconUrl: '/icons/apex-pop-icon-dark.svg',
    reservoirBaseUrl: reservoirChains.boss.baseApiUrl,
    proxyApi: `${proxyDisabled ? '/api/reservoir' : ''}/boss`,
    routePrefix: 'boss',
    apiKey: process.env.RESERVOIR_API_KEY,
    coingeckoId: 'ethereum',
    oracleBidsEnabled: true,
    checkPollingInterval: reservoirChains.boss.checkPollingInterval,
    blockExplorers: {
      default: {
        name: 'Boss Explorer',
        url: 'https://explorer.boss.proofofplay.com',
      },
    },
  },
  {
    ...base,
    lightIconUrl: '/icons/base-icon-dark.svg',
    darkIconUrl: '/icons/base-icon-light.svg',
    reservoirBaseUrl: reservoirChains.base.baseApiUrl,
    proxyApi: '/api/reservoir/base',
    routePrefix: 'base',
    coingeckoId: 'ethereum',
    collectionSetId: process.env.NEXT_PUBLIC_BASE_COLLECTION_SET_ID,
    community: process.env.NEXT_PUBLIC_BASE_COMMUNITY,
    oracleBidsEnabled: true,
    checkPollingInterval: reservoirChains.base.checkPollingInterval,
  },
  // Chains we use for Cross-Chain
  {
    ...arbitrum,
    name: 'Arbitrum',
    lightIconUrl: '/icons/arbitrum-icon-dark.svg',
    darkIconUrl: '/icons/arbitrum-icon-light.svg',
    reservoirBaseUrl: reservoirChains.arbitrum.baseApiUrl,
    proxyApi: '/api/reservoir/arbitrum',
    routePrefix: 'arbitrum',
    coingeckoId: 'arbitrum-iou',
    collectionSetId: process.env.NEXT_PUBLIC_ARBITRUM_COLLECTION_SET_ID,
    community: process.env.NEXT_PUBLIC_ARBITRUM_COMMUNITY,
    wssUrl: 'wss://ws-arbitrum.reservoir.tools',
    listingCurrencies: [
      { ...nativeCurrencyBase, coinGeckoId: 'arbitrum-iou' },
      {
        ...usdcCurrencyBase,
        contract: usdcContracts[arbitrum.id],
      },
    ],
    oracleBidsEnabled: true,
    checkPollingInterval: reservoirChains.arbitrum.checkPollingInterval,
  },
  {
    ...optimism,
    name: 'Optimism',
    lightIconUrl: '/icons/optimism-icon-dark.svg',
    darkIconUrl: '/icons/optimism-icon-light.svg',
    reservoirBaseUrl: reservoirChains.optimism.baseApiUrl,
    proxyApi: '/api/reservoir/optimism',
    routePrefix: 'optimism',
    coingeckoId: 'optimism',
    collectionSetId: process.env.NEXT_PUBLIC_OPTIMISM_COLLECTION_SET_ID,
    community: process.env.NEXT_PUBLIC_OPTIMISM_COMMUNITY,
    wssUrl: 'wss://ws-optimism.reservoir.tools',
    listingCurrencies: [
      { ...nativeCurrencyBase, coinGeckoId: 'optimism' },
      {
        ...usdcCurrencyBase,
        contract: usdcContracts[optimism.id],
      },
    ],
    oracleBidsEnabled: true,
    checkPollingInterval: reservoirChains.optimism.checkPollingInterval,
  },
  {
    ...zora,
    name: 'Zora',
    lightIconUrl: '/icons/zora-icon-dark.svg',
    darkIconUrl: '/icons/zora-icon-light.svg',
    reservoirBaseUrl: reservoirChains.zora.baseApiUrl,
    proxyApi: '/api/reservoir/zora',
    routePrefix: 'zora',
    coingeckoId: 'ethereum',
    oracleBidsEnabled: true,
    checkPollingInterval: reservoirChains.zora.checkPollingInterval,
  },
  {
    ...customChains.blast,
    name: 'Blast',
    lightIconUrl: '/icons/blast-icon-light.svg',
    darkIconUrl: '/icons/blast-icon-dark.svg',
    reservoirBaseUrl: reservoirChains.blast.baseApiUrl,
    proxyApi: '/api/reservoir/blast',
    routePrefix: 'blast',
    apiKey: process.env.RESERVOIR_API_KEY,
    coingeckoId: 'ethereum',
    collectionSetId: process.env.NEXT_PUBLIC_BLAST_COLLECTION_SET_ID,
    community: process.env.NEXT_PUBLIC_BLAST_COMMUNITY,
    oracleBidsEnabled: true,
    checkPollingInterval: reservoirChains.blast.checkPollingInterval,
    rpcUrls: {
      default: {
        http: ['https://rpc.blast.io']
      },
      public: {
        http: ['https://rpc.blast.io']
      },
    }
  },
  {
    ...arbitrumNova,
    lightIconUrl: '/icons/arbitrum-nova-icon-dark.svg',
    darkIconUrl: '/icons/arbitrum-nova-icon-light.svg',
    reservoirBaseUrl: reservoirChains.arbitrumNova.baseApiUrl,
    proxyApi: '/api/reservoir/arbitrum-nova',
    routePrefix: 'arbitrum-nova',
    coingeckoId: 'ethereum',
    collectionSetId: process.env.NEXT_PUBLIC_ARBITRUM_NOVA_COLLECTION_SET_ID,
    community: process.env.NEXT_PUBLIC_ARBITRUM_NOVA_COMMUNITY,
    oracleBidsEnabled: true,
    checkPollingInterval: reservoirChains.arbitrumNova.checkPollingInterval,
  },
] as ReservoirChain[]

export const ETHChains: number[] = [mainnet.id, sepolia.id]

export default process.env.NEXT_PUBLIC_PUBLIC_ENVIRONMENT === 'production'
  ? live
  : testnets
